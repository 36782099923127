import { useState } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { blue, blueGrey } from "@material-ui/core/colors";

const defaultTheme = {
  palette: {
    primary: blue,
    secondary: blueGrey
  },
  typography: {
  },
  status: {
  },
};

export function useTheme() {
  const [currentTheme, setCurrentTheme] = useState(defaultTheme);
  const muiTheme = createMuiTheme({
    ...defaultTheme,
    ...currentTheme,
  });
  return [muiTheme, setCurrentTheme];
}

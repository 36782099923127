import { Button } from "@material-ui/core";
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
    login: boolean;
};

const Splash = ({ login = false }: Props) => {
    const { loginWithRedirect } = useAuth0();
    return (
        <div className="App">
            <header className="App-header">
                <p>MinerWatch</p>
                {login ? <Button variant="contained" color="primary" onClick={() => loginWithRedirect()}>Login</Button> : <p>Loading...</p>}
            </header>
        </div>
        );
}

export default Splash;
import React, { lazy, Suspense } from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import Splash from './components/Splash';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { useTheme } from './theme';
const MainDrawer = lazy(() => import('./components/MainDrawer'))
const Miners = lazy(() => import('./components/miners/Miners'))
const About = lazy(() => import('./components/about/About'));
const Dashboard = lazy(() => import('./components/dashboard/Dashboard'));

function App() {
  const { isAuthenticated, isLoading } = useAuth0();
  const [currentTheme] = useTheme();

  if (!isAuthenticated && !isLoading) {
    return (
      <Splash login={true} />
    );
  }

  return (
    <ThemeProvider theme={currentTheme}>
      <BrowserRouter>
        <Suspense fallback={<Splash login={false} />}>
          <CssBaseline />
          <MainDrawer>
            <Switch>
              <Route path="/miners">
                <Miners />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/">
                <Dashboard />
              </Route>
            </Switch>
          </MainDrawer>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
